import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import './index.less';
import Beian from './beian.png';
import { Space } from 'antd';

const Footer = () => {
  return (
    <div className="home-page-wrapper footer-wrapper">
      <OverPack className="home-page footer" playScale={0.05}>
        <TweenOne
          animation={{ y: '+=30', opacity: 0, type: 'from' }}
          key="footer"
          className="copyright lngq8zw9de-editor_css"
        >
          <Space align="center" wrap>
            <a target="_blank" href="https://beian.miit.gov.cn/">
              苏ICP备2023013405号-3
            </a>
            <span>
              ©2023{' '}
              <a href="http://www.mouyoung.cn">南京陌羊数据科技有限公司</a>
              &nbsp;All Rights Reserved&nbsp;&nbsp;
            </span>
            <span>
              <img src={Beian} />
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010002010046"
              >
                苏公网安备32010002010046号
              </a>
            </span>
          </Space>
        </TweenOne>
      </OverPack>
    </div>
  );
};

export default Footer;
