import React from 'react';
import './content0.less';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import OverPack, { IProps } from 'rc-scroll-anim/lib/ScrollOverPack';
import Item0Png from './item0.png';
import Item1Png from './item1.png';
import Item2Png from './item2.png';

const CR = React.forwardRef<
  IProps & {
    ref?: React.Ref<any>;
  }
>(() => {
  return <Row />;
});
const Content0 = () => {
  return (
    <div className="home-page-wrapper content0-wrapper">
      <div className="home-page content0">
        <div className="title-wrapper">
          <h1>
            <span>产品与服务</span>
          </h1>
        </div>
        <OverPack playScale={0.3}>
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            // component={CR}
            componentProps={{ className: 'content0-block-wrapper' }}
          >
            <Col key="block0" className="content0-block" md={8} xs={24}>
              <div className="content0-block-item">
                <div className="content0-block-icon">
                  <img src={Item0Png} />
                </div>
                <h1 className="content0-block-title">
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>数字化扫描管理</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h1>
                <div>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <p>
                                          数字化业务扫描、质检、录入全业务流程工序化管理，同时支持国产化和非国产化
                                        </p>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Col>
            <Col key="block1" className="content0-block" md={8} xs={24}>
              <div className="content0-block-item">
                <div className="content0-block-icon">
                  <img src={Item1Png} />
                </div>
                <h1 className="content0-block-title">
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>校安智慧综管平台</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h1>
                <div>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <p>
                                          基于门禁、摄像机、抓拍机等设备对学生校园行为进行综合分析，扼杀危险于摇篮之中
                                        </p>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Col>
            <Col key="block2" className="content0-block" md={8} xs={24}>
              <div className="content0-block-item">
                <div className="content0-block-icon">
                  <img src={Item2Png} />
                </div>
                <h1 className="content0-block-title">
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>工程车管理</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h1>
                <div>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <p>
                                          通过APP小程序自动生成车辆运输订单，将每次运载情况实时记录，避免纸质账单丢失等情况
                                        </p>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Col>
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  );
};

export default Content0;
