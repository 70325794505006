import React, { useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import './content1.less';
import Content1Png from './content1.png';

const Content1 = (props: any) => {
  const { isMobile } = props;
  const animType: any = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          ease: 'easeOutQuad',
          type: 'from',
        }
      : {
          x: '-=30',
          opacity: 0,
          ease: 'easeOutQuad',
          type: 'from',
        },
  };

  return (
    <div className="home-page-wrapper content1-wrapper">
      {/* <OverPack className="home-page content1" playScale={0.3}>
        <Row>
          <TweenOne
            key="img"
            animation={animType.one}
            resetStyle
            className="content1-img"
            component={Col}
            componentProps={{
              md: 10,
              xs: 24,
            }}
          >
            <span>
              <img src={Content1Png} width="100%" alt="img" />
            </span>
          </TweenOne>
          <Col md={14} xs={24}>
            <QueueAnim
              key="text"
              type={animType.queue}
              leaveReverse
              ease={['easeOutQuad', 'easeInQuad']}
              className="content1-text"
              // component={Col}
              componentProps={{
                md: 14,
                xs: 24,
              }}
            >
              <h2 key="h1" className="content1-title">
                <span>
                  <p>智慧档案管理</p>
                </span>
              </h2>
              <div key="p" className="content1-content">
                <span>
                  <span>
                    <p>
                      档案收集、整理、管理、利用全流程管理，同时支持国产化和非国产化。
                      <br />
                    </p>
                  </span>
                </span>
              </div>
            </QueueAnim>
          </Col>
        </Row>
      </OverPack> */}
    </div>
  );
};

export default Content1;
