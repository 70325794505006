import React, { useEffect, useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import '~/rc-texty/assets/index.css';

import './index.less';
const Banner = () => {
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  });
  return (
    <div className="banner">
      <QueueAnim
        key="QueueAnim"
        type={['bottom', 'top']}
        delay={200}
        className="banner-text-wrapper"
      >
        <div className="banner-slogan" key="slogan">
          <Texty type="mask-bottom" mode="smooth">
            南京陌羊数据科技有限公司
          </Texty>
        </div>
        <div className="banner-name" key="name">
          专注软件开发服务
        </div>
      </QueueAnim>
    </div>
  );
};

export default Banner;
