import React from 'react';
import './content.less';
import Content0 from './Content0';
import Content1 from './Content1';

const Content = (props: any) => {
  return (
    <>
      <Content0 {...props}></Content0>
      <Content1 {...props}></Content1>
    </>
  );
};

export default Content;
