import React from 'react';
import Banner from './component/Banner';
import Content from './component/Content';
import Footer from './component/Footer';

import './index.less';

const Index = () => {
  return (
    <div className="templates-wrapper">
      <Banner />
      <Content />
      <Footer />
    </div>
  );
};

export default Index;
